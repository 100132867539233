<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="thead">
          <th width="7%">Action</th>
        </template>
        <template slot="tbody">
          <vs-tr
            :key="indextr"
            v-for="(tr, indextr) in responseData.cashBank"
            :class="tr.class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <div class="mt-4 flex justify-between">
                  <vx-tooltip text="Show" class="mr-4">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      v-on:click.stop="handleShow(tr)"
                    />
                  </vx-tooltip>
                </div>
              </template>
            </vs-td>
            <vs-td>
              {{ tr.Code }}
            </vs-td>
            <vs-td>
              {{
                tr.BankName +
                " - " +
                tr.BankBranchName +
                " (" +
                tr.AccountNumber +
                " " +
                tr.AccountName +
                ") "
              }}
            </vs-td>
            <vs-td> {{ priceFormat(tr.DepositValue) }}<br /> </vs-td>
            <vs-td> Method : {{ methods[tr.Method] }}<br /> </vs-td>
            <vs-td>
              Deposit Number : {{ tr.DepositNumber }}<br />
              <template v-if="tr.GiroBankID != 0">
                Customer : {{ "(" + tr.PersonCode + ") " + tr.PersonName }}
                <br />
                {{ methods[tr.Method] }} Bank : {{ tr.GiroBankName }}<br />
                {{ methods[tr.Method] }} Number : {{ tr.GiroNumber }}<br />
                {{ methods[tr.Method] }} Date :
                {{ globalDateFormat(tr.GiroDate) }}<br />
              </template>
            </vs-td>
          </vs-tr>
        </template>
      </data-table>
      <!-- Approve Prompt -->
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="approve"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="approvalPrompt"
      >
        <div class="con-exemple-prompt">
          Cash Deposit
          <br />
          Are you sure to release draft <b>{{ this.selectedData.Code }}</b> ?
          <br />
          <br />
          <vs-textarea v-model="notes" />
        </div>
      </vs-prompt>
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail-div @close="handleClose" :selected="selectedData" />
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import detail from "./detail.vue";
import { jsPDF } from "jspdf";
import moment from "moment";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
  },
  props: {
    selected: Object,
    option: Object,
    depositDate: Object,
    collectionDate: Object,
    territory: Object,
    isReload: Boolean,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Bank",
          value: "bank_name",
        },
        {
          text: "Deposit Value",
          value: "deposit_value",
        },
        {
          text: "Method",
          value: "method",
        },
        {
          text: "Information",
          sortable: false,
        },
      ],
      methods: ["", "Cash", "Giro", "Cheque", "Transfer"],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
    };
  },
  computed: {},
  watch: {
    // "depositDate"() {
    //   this.reloadData(this.params)
    // },
    // "collectionDate"() {
    //   this.reloadData(this.params)
    // },
    // "territory"() {
    //   this.reloadData(this.params)
    // }
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },

    approve() {
      this.doSubmit();
      // console.log(this.selectedData)
    },
    doSubmit() {
      this.$vs.loading();
      const params = {
        status_purchase_request: 1,
        purchase_payment_request_id: this.selectedData.ID,
        notes: this.notes,
      };

      this.$http
        .post("/api/v1/purchase-payment-request/change-status", params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params);
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/cash-bank/data-table", {
            params: {
              status: 6,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              territory_ids: params.territory_ids,
              start_collect_date: this.collectionDate.startDate
                ? moment(this.collectionDate.startDate).format("YYYY-MM-DD")
                : null,
              end_collect_date: this.collectionDate.endDate
                ? moment(this.collectionDate.endDate).format("YYYY-MM-DD")
                : null,
              start_deposit_date: this.depositDate.startDate
                ? moment(this.depositDate.startDate).format("YYYY-MM-DD")
                : null,
              end_deposit_date: this.depositDate.endDate
                ? moment(this.depositDate.endDate).format("YYYY-MM-DD")
                : null,
              territory_id: this.territory ? this.territory.id : null,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getBase64Image(url) {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        // console.log(dataURL)
      };
      img.src = url;
      return img;
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    handleClose() {
      this.reloadData(this.params);
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    reloadData(params) {
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      this.params = {
        ...params,
        territory_ids,
      };

      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.cashBank;
          this.responseData = r.data;
          this.responseData.length = r.data.cashBank.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
