var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Cash Deposit Reversal"}},[_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Deposit Date")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full whitespace-no-wrap"},[_c('div',{staticClass:"mt-4 flex justify-between"},[_c('date-range-picker',{ref:"picker",staticClass:"w-full",staticStyle:{"min-height":"40px"},attrs:{"opens":"center","locale-data":{ firstDay: 1, format: 'dd-mm-yyyy' },"singleDatePicker":false,"timePicker":false,"timePicker24Hour":false,"showWeekNumbers":false,"showDropdowns":false,"autoApply":true,"linkedCalendars":true},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(_vm.globalDateFormat(picker.startDate))+" - "+_vm._s(_vm.globalDateFormat(picker.endDate))+" ")]}}]),model:{value:(_vm.depositDate),callback:function ($$v) {_vm.depositDate=$$v},expression:"depositDate"}}),_c('vs-button',{staticClass:"ml-4",attrs:{"color":"danger","icon-pack":"feather","icon":"icon-x"},on:{"click":() => {
              this.depositDate = { startDate: null, endDate: null };
            }}})],1)])]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Collection Date")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full whitespace-no-wrap"},[_c('div',{staticClass:"mt-4 flex justify-between"},[_c('date-range-picker',{ref:"picker",staticClass:"w-full",staticStyle:{"min-height":"40px"},attrs:{"opens":"center","locale-data":{ firstDay: 1, format: 'dd-mm-yyyy' },"singleDatePicker":false,"timePicker":false,"timePicker24Hour":false,"showWeekNumbers":false,"showDropdowns":false,"autoApply":true,"linkedCalendars":true},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(_vm.globalDateFormat(picker.startDate))+" - "+_vm._s(_vm.globalDateFormat(picker.endDate))+" ")]}}]),model:{value:(_vm.collectDate),callback:function ($$v) {_vm.collectDate=$$v},expression:"collectDate"}}),_c('vs-button',{staticClass:"ml-4",attrs:{"color":"danger","icon-pack":"feather","icon":"icon-x"},on:{"click":() => {
              this.collectDate = { startDate: null, endDate: null };
            }}})],1)])]),_c('div',{staticStyle:{"width":"49%"}},[_c('operating-unit-filter',{attrs:{"full":true},on:{"onShow":_vm.onShow}})],1),_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + 'md:w-1/4 w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-tabs',{attrs:{"color":_vm.colorx}},[_c('vs-tab',{attrs:{"label":"Waiting Approval Reversal"},on:{"click":function($event){_vm.colorx = 'warning'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('waiting',{attrs:{"collectionDate":_vm.collectDate,"depositDate":_vm.depositDate,"isReload":_vm.isReload}})],1)]),_c('vs-tab',{attrs:{"label":"Failed"},on:{"click":function($event){_vm.colorx = 'primary'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('failed',{attrs:{"collectionDate":_vm.collectDate,"depositDate":_vm.depositDate,"territory":_vm.territory,"isReload":_vm.isReload}})],1)]),_c('vs-tab',{attrs:{"label":"Canceled"},on:{"click":function($event){_vm.colorx = 'primary'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('cancel',{attrs:{"collectionDate":_vm.collectDate,"depositDate":_vm.depositDate,"territory":_vm.territory,"isReload":_vm.isReload}})],1)]),_c('vs-tab',{attrs:{"label":"Reversed"},on:{"click":function($event){_vm.colorx = 'primary'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('reversed',{attrs:{"collectionDate":_vm.collectDate,"depositDate":_vm.depositDate,"territory":_vm.territory,"isReload":_vm.isReload}})],1)])],1)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? _vm.detailShow + 'md:w-3/4 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1),_c('vs-col',{staticClass:"vertical-divider",attrs:{"vs-offset":"0","vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"1"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}})],1),_c('create',{on:{"close":_vm.handleClose}})],1)],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }